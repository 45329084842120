import React, { useState, Fragment } from 'react';
import { Layout, Image, Menu, Swiper, Tag } from 'tdesign-react';

const { SwiperItem } = Swiper;

const { HeadMenu, MenuItem } = Menu;

const { Header, Content, Footer } = Layout;

export default function IndexPage() {

  const [active, setActive] = useState('0');
  const mask = (
    <div
      style={{
        background: 'rgba(0,0,0,.4)',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        lineHeight: 22
      }}
    >
      <Tag
        shape="mark"
        theme="warning"
        style={{
          borderRadius: 3,
          background: 'transparent',
          color: '#fff'
        }}
      >
      </Tag>
    </div>
  );

  return (
    <Fragment>
      <Layout>
        <Header>
          <HeadMenu
            theme="dark"
            value={active}
            onChange={(v) => setActive(v)}
            logo={<img src="/logo192.png" height="28" alt="logo" />}
            style={{ marginBottom: 20 }}
          >
            <MenuItem value={'0'}>
              <span><a href="/">首页</a></span>
            </MenuItem>
          </HeadMenu>
        </Header>
        <Content>
          <div className="poster-swiper">
            <Swiper animation="slide"
              autoplay={true}
              direction="horizontal"
              duration={100}
              interval={2000}
              loop
              theme="light"
              trigger="hover"
              stopOnHover={false}
              type="default" navigation={{ size: 'large' }}>
              <SwiperItem>
                <div className="poster-item">
                  <Image
                    src="/poster1.jpeg"
                    overlayContent={mask}
                  />
                </div>
              </SwiperItem>
              <SwiperItem>
                <div className="poster-item">
                  <Image
                    src="/poster2.jpeg"
                    overlayContent={mask}
                  />
                </div>
              </SwiperItem>
            </Swiper>
          </div>
        </Content>
        <Footer>
          <p><a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">粤ICP备2024238221号</a> Copyright © 2024 深圳市齐泰贸易有限公司版权所有</p>
        </Footer>
      </Layout>

    </Fragment>
  );
}
